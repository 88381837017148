<template>
    <b-card-code no-body :title="title + ' List'">
        <div class="dt_adv_search ml-1 mr-1">
            <div class="row">

                <div class="col-md-6">
                    <b-button @click="cleanUpForm()" v-if="checkPermission('create content') && enableAdd" v-b-modal.form-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" :title="'Add ' + title">
                        <feather-icon
                            icon="PlusCircleIcon"
                        />
                        Add {{ title }}
                    </b-button>
                </div>

            </div>
        </div>
        <b-modal no-close-on-backdrop id="form-modal"  size="lg" :title="editSlug != null ? 'Edit ' + title : 'Add ' + title">
            <div class="form">

                <div class="row" v-if="useCover">
                    <div class="col-6">
                        <div class="form-group">
                        <label for="file">{{ title }} Image:</label><br>
                        <input type="file" id="file" ref="file" @change="changeCover()">
                        </div>
                    </div>
                    <div class="col-6">
                        <div v-if="formPayload.cover_url != null">
                        <small>Current Image:</small><br>
                        <img v-bind:src="formPayload.cover_url" style="max-width: 100%;">
                        </div>
                    </div>
                </div>

                <div v-if="isStaticTitleEditable">
                  <div class="form-group">
                    <label for="title">Title:</label>
                    <input type="text" class="form-control" id="title" v-model="formPayload.title">
                  </div>
                </div>

                <div v-if="useGlobalCta">
                  <div class="form-group">
                    <label for="cta_url">CTA URL:</label>
                    <input type="url" class="form-control" id="cta_url" v-model="formPayload.cta_url">
                  </div>
                </div>

                <div class="form-group" v-bind:class="hideContent ? 'd-none' : ''">
                    <b-tabs>
                        <b-tab v-for="(trans, langIndex) in formPayload.translations" :key="trans.lang" :title="trans.label">
                            <div class="form-group" v-bind:class="hideTitleContent ? 'd-none' : ''">
                                <label for="">Title:</label>
                                <input type="text" class="form-control" v-model="formPayload.translations[langIndex].title" :placeholder="'Enter title in ' + trans.label + '...'">
                            </div>

                            <div class="row" v-if="useCoverContent">
                              <div class="col-6">
                                  <div class="form-group">
                                  <label for="image">{{ title }} Image:</label><br>
                                  <input type="file" id="cover_id" ref="cover_id" @change="changeCoverContent($event.target.files, langIndex)">
                                  </div>
                              </div>
                              <div class="col-6">
                                  <div v-if="formPayload.translations[langIndex].cover_url != null">
                                  <small>Current Image:</small><br>
                                  <img v-bind:src="formPayload.translations[langIndex].cover_url" style="max-width: 100%;">
                                  </div>
                              </div>
                            </div>

                            <div v-if="useContentCta">
                              <div class="form-group">
                                <label for="cta_url_content">CTA URL:</label>
                                <input type="url" class="form-control" id="cta_url_content" v-model="formPayload.translations[langIndex].cta_url">
                              </div>
                            </div>

                            <div class="form-group" v-bind:class="hideDescriptionContent ? 'd-none' : ''">
                                <label for="">Content:</label>
                                <quill-editor
                                v-model="formPayload.translations[langIndex].content"
                                :placeholder="'Enter content in ' + trans.label + '...'"
                                :options="formPayload.translations[langIndex].editorOption"
                                >
                                <div
                                    :id="'toolbar' + trans.lang"
                                    slot="toolbar"
                                >
                                    <!-- Add a bold button -->
                                    <button class="ql-bold">
                                    Bold
                                    </button>
                                    <button class="ql-italic">
                                    Italic
                                    </button>

                                    <!-- Add font size dropdown -->
                                    <select class="ql-size">
                                    <option value="small" />
                                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                                    <option selected />
                                    <option value="large" />
                                    <option value="huge" />
                                    </select>

                                    <select class="ql-font">
                                    <option selected="selected" />
                                    <option value="serif" />
                                    <option value="monospace" />
                                    </select>

                                    <!-- Add subscript and superscript buttons -->
                                    <button
                                    class="ql-script"
                                    value="sub"
                                    />
                                    <button
                                    class="ql-script"
                                    value="super"
                                    />

                                </div>
                                </quill-editor>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>

            </div>

            <template #modal-footer="{}">
                <b-button variant="success" @click="editSlug == null ? createItem() : updateItem()">
                Save {{ title }}
                </b-button>
            </template>
        </b-modal>
        <div class="table-responsive-sm">
            <table class="table b-table table-fixed">
                <thead>
                <tr>
                    <!-- <th>Slug</th> -->
                    <th>Title</th>
                    <th>Created By</th>
                    <th>Published At</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="isLoading">
                    <td colspan="4" class="text-center">
                    <b-spinner
                        class="mb-1"
                        variant="primary"
                    /><br>
                    Loading...
                    </td>
                </tr>
                <tr v-else v-for="item in result.data" v-bind:key="item.id">
                    <!-- <td>{{ item.slug }}</td> -->
                    <td v-if="isStaticTitle">{{ item.static_title }}</td>
                    <td v-else>{{ item.title }}</td>
                    <td>{{ item.created_by != null ? item.created_by.name : '-' }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>
                        <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-info btn-sm" v-if="checkPermission('update content')" @click="editItem(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </button>
                        <button class="btn btn-danger btn-sm" v-if="checkPermission('delete content') && enableDelete" @click="deleteItem(item.slug)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Delete">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </button>
                    </div>
                    </td>
                </tr>
                <tr v-if="result.total == 0 && !isLoading">
                    <td colspan="4" class="text-center">Data is empty.</td>
                </tr>
                </tbody>
            </table>

        <div class="m-1" v-if="result.total > 0">
            <div class="row">
            <div class="col">
                <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
            </div>
            <div class="col">
                <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
            </div>
            </div>
        </div>
        </div>
    </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BTab, BTabs } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: true
    },
    enableAdd: {
      type: Boolean,
      required: true
    },
    enableDelete: {
      type: Boolean,
      required: true
    },
    useCover: {
      type: Boolean,
      required: true
    },
    isStaticTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    useCoverContent: {
      type: Boolean,
      required: false,
      default: false
    },
    hideTitleContent: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDescriptionContent: {
      type: Boolean,
      required: false,
      default: false
    },
    hideContent: {
      type: Boolean,
      required: false,
      default: false
    },
    isStaticTitleEditable: {
      type: Boolean,
      required: false,
      default: false
    },
    useGlobalCta: {
      type: Boolean,
      required: false,
      default: false
    },
    useContentCta: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BTab,
    BTabs,
    quillEditor
  },
  watch: {
    filter: {
      handler: _.debounce(function() {      
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
        currentPage: 1,
        result: {},
        isLoading: false,
        formPayload: Object,
        covers: Object,
        editSlug: null,
        parents: [],
        languages: [
            {
                key: 'id',
                label: 'Indonesia'
            },
            {
                key: 'en',
                label: 'English'
            },
        ],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
        var translations = [];
        this.languages.forEach(function(item){
            translations.push({
                lang: item.key,
                label: item.label,
                title: '',
                content: '',
                cta_url: '',
                cover: null,
                editorOption: {
                    modules: {
                        toolbar: '#toolbar' + item.key,
                    },
                }
            });
        })
        this.editSlug = null
        this.formPayload = {
            cover: null,
            title: null,
            cta_url: null,
            type: this.contentType,
            translations: translations
        };
    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = {}
      queryParams.page = this.currentPage
      queryParams.type = this.contentType

      this.$http.get('/admin/contents', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    changeCover() {
        this.formPayload.cover = this.$refs.file.files[0];
    },
    changeCoverContent(fileList, langIndex) {
        if (!fileList.length) return;
        this.formPayload.translations[langIndex].cover = fileList[0];
    },
    createItem() {
        var form = this.preparePayload();

        this.$http.post('/admin/contents', form, {
            headers: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {

            this.$bvModal.hide('form-modal')
            this.getData(this.currentPage)
            successNotification(this, 'Success', this.title + ' successfully created')
            this.cleanUpForm();

        })
        .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)  
            }
        })
    },
    updateItem() {
      var form = this.preparePayload();
      form.append('_method', 'PATCH');

      this.$http.post('/admin/contents/' + this.editSlug, form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', this.title + ' successfully updated!')
        this.cleanUpForm();

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)          
        }
      })
    },
    preparePayload() {      
        var form = new FormData();
        
        // Automate get type
        form.append('type', this.contentType);

        // Prepare payload
        for (var key in this.formPayload) {
            if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
                form.append(key, this.formPayload[key]);
            }
        }

        // Prepare translation
        if (!this.hideContent) {
          var context = this
          this.formPayload.translations.forEach(function(item, index){
              form.append('translations[' + index + '][lang]', item.lang);
              form.append('translations[' + index + '][title]', item.title);
              form.append('translations[' + index + '][content]', item.content);
              form.append('translations[' + index + '][cta_url]', item.cta_url);
              if (item.cover != null) {
                form.append('translations[' + index + '][cover]', item.cover);
              }
          });
        }

        return form;
    },
    editItem(item) {
        this.editSlug = item.slug

        // Get detail
        this.$http.get('/admin/contents/' + this.editSlug)
        .then(response => {
            var detail = response.data.data
            
            var translations = [];
            if (typeof detail.translations !== 'undefined') {
              detail.translations.forEach(function(translation){
                  translations.push({
                      lang: translation.lang,
                      label: translation.lang_label,
                      cover_url: translation.cover_url,
                      title: translation.title,
                      content: translation.content,
                      cta_url: translation.cta_url,
                      editorOption: {
                          modules: {
                              toolbar: '#toolbar' + translation.lang,
                          },
                      }
                  });
              })
            }

            this.formPayload = {
                cover_url: detail.cover_url,
                type: detail.type,
                title: detail.static_title,
                cta_url: detail.cta_url,
                translations: translations
            };

            this.$bvModal.show('form-modal')
        })
        
    },
    deleteItem(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.delete('admin/contents/' + id)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Item successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>